import { addDays, format, parse, isValid  } from 'date-fns';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import { useWindowWidth } from '@react-hook/window-size';
import cn from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import styles from './FlightSearchDate.module.scss';
import { CalendarNav, CalendarNext, CalendarPrev, Datepicker } from '@mobiscroll/datepicker-react';

const pickerTheme = createTheme({
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      main: '#0a0a0a',
    },
  },
  typography: {
    fontFamily: '"Libre Franklin", sans-serif',
  },
});

export const BCCDatePicker = memo(
  ({
    labelStart,
    labelEnd,
    name,
    setFieldValue,
    mode = 'single',
    viewMode = 'vertical',
    error,
    value,
    rangeValue,
    // allowSameDateSelection = true,
    rangeLimitation = 360,
    maxDate = addDays(new Date(), rangeLimitation),
    minDate = format(new Date(), 'yyyy-MM-dd'),
    errorMessages = null,
    primaryColor = '#00454A',
    allowPast,
  }) => {
    const inputTheme = createTheme({
      shape: {
        borderRadius: 12,
        paddingRight: 0,
      },
      palette: {
        primary: {
          main: primaryColor,
        },
      },
      typography: {
        fontFamily: '"Libre Franklin", sans-serif',
      },
    });

    const [valueRange, setValueRange] = useState([null, null]);
    const [valueRangeFormatted, setValueRangeFormatted] = useState([
      null,
      null,
    ]);
    const [dateValueFormatted, setDateValueFormatted] = useState(
      value ? format(parse(value, 'yyyy-MM-dd', new Date()), 'E, dd MMM') : null
    );
    const [patched, setPatched] = useState(false);
    const [dateValue, setValue] = useState(value);
    const [open, setOpen] = useState(false);
    const [singleOpen, setSingleOpen] = useState(false);
    const width = useWindowWidth();
    const [start, startRef] = useState(null);

    // function getLang() {
    //   if (navigator.languages != undefined) return navigator.languages[0];
    //   return navigator.language;
    // }

    const [end, endRef] = useState(null);
    const [single, singleRef] = useState(null);
    const [firstDayOfTheWeek, setFirstDayOfTheWeek] = useState(null);
    const [activeInput, setActiveInput] = useState(null);

    useEffect(() => {
      if (!patched && rangeValue && rangeValue[0] && rangeValue[1]) {
        const parsed = [
          parse(rangeValue[0], 'yyyy-MM-dd', new Date()),
          parse(rangeValue[1], 'yyyy-MM-dd', new Date()),
        ];
        setValueRange(parsed);
        setValueRangeFormatted([
          format(parsed[0], 'E, dd MMM'),
          format(parsed[1], 'E, dd MMM'),
        ]);
        // setPatched(true); // It not updated on change for DealsDialog, could break smth enywhere?
      }
    }, [rangeValue]);

    useEffect(() => {
      if (!patched && value) {
        const parsed = parse(value, 'yyyy-MM-dd', new Date());
        setValue(parsed);
        setDateValueFormatted(format(parsed, 'E, dd MMM'));
        setPatched(true);
      }
    }, [value]);
    useEffect(() => {
      // const firstDayOfWeek = getFirstDayOfWeek();
      setFirstDayOfTheWeek(firstDayOfTheWeek);
    }, []);
    // function getFirstDayOfWeek() {
    //   // todo improve this logic
    //   let firstDay;
    //   try {
    //     // Ensure that navigator.language or navigator.languages[0] provides a valid BCP 47 language tag
    //     const locale =
    //       void 0 !== navigator.languages && navigator.languages.length > 0
    //         ? navigator.languages[0]
    //         : navigator.language;
    //
    //     // Check for Intl.Locale and its weekInfo support
    //     if (Intl.Locale && typeof Intl.Locale.prototype === 'object') {
    //       const localeObj = new Intl.Locale(locale);
    //       if (
    //         localeObj.weekInfo &&
    //         typeof localeObj.weekInfo.firstDay === 'number'
    //       ) {
    //         firstDay = localeObj.weekInfo.firstDay;
    //       }
    //     }
    //   } catch (error) {
    //     console.error('Error determining first day of the week:', error);
    //     // Apply fallback or default value
    //   }
    //
    //   // If the weekInfo or firstDay wasn't accessible or valid, use a fallback
    //   if (typeof firstDay !== 'number') {
    //     console.warn('Using fallback for first day of the week.');
    //     // You may need to hardcode this or provide a default based on typical locale assumptions
    //     firstDay = 1; // Assuming Monday as a common first day of the week
    //   }
    //
    //   return firstDay;
    // }
    const openDateSelection = (input) => {
      if (width >= 1024) {
        window.parent.postMessage(
          JSON.stringify({
            height: 55,
          }),
          '*'
        );
      }
      if (startRef && endRef) {
        setActiveInput(input);
        setOpen(true);
      }
    };

    const onOpen = (event, inst) => {
      if (activeInput === 'end') {
        inst.setActiveDate('end');
      } else {
        inst.setActiveDate('start');
      }
    };
    const rangeInputs = () =>
      startRef &&
      endRef && (
        <ThemeProvider theme={inputTheme}>
          <div
            className={cn({
              'flex w-full': viewMode === 'horizontal',
              'flex-col w-full': viewMode === 'vertical',
            })}
          >
            <div
              className={cn({
                'mr-2 w-full': viewMode === 'horizontal',
                'mb-4': viewMode === 'vertical',
              })}
            >
              <TextField
                error={error}
                label={labelStart}
                placeholder={labelStart}
                inputProps={{
                  autoComplete: 'off',
                }}
                value={valueRangeFormatted[0]}
                ref={startRef}
                fullWidth
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => openDateSelection('start')}
                InputProps={{ endAdornment: <DateRangeIcon className="cursor-pointer" /> }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                }}
              />
              {errorMessages && errorMessages.departure && (
                <div className={styles.errorMessage}>
                  {errorMessages.departure}
                </div>
              )}
            </div>
            <div
              className={cn({
                'w-full': viewMode === 'horizontal',
              })}
            >
              <TextField
                error={error}
                label={labelEnd}
                placeholder={labelEnd}
                inputProps={{
                  autoComplete: 'off',
                  onClick: () => openDateSelection('end'),
                }}
                value={valueRangeFormatted[1]}
                ref={endRef}
                fullWidth
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => openDateSelection('end')}
                InputProps={{ endAdornment: <DateRangeIcon className="cursor-pointer"/> }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    cursor: 'pointer',
                  },
                }}
              />
              {errorMessages && errorMessages.return && (
                <div className={styles.errorMessage}>
                  {errorMessages.return}
                </div>
              )}
            </div>
          </div>
        </ThemeProvider>
      );
    const renderSingle = () => (
      <ThemeProvider theme={inputTheme}>
        <TextField
          inputProps={{
            autoComplete: 'off',
          }}
          error={error}
          fullWidth
          value={dateValueFormatted}
          label={labelStart}
          placeholder={labelStart}
          ref={singleRef}
          onClick={() => setSingleOpen(true)}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          InputProps={{ endAdornment: <DateRangeIcon /> }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </ThemeProvider>
    );
    const onChange = (newValue) => {
      if (newValue?.value.length) {
        setValueRange(newValue.value);

        if (newValue.value[0] && newValue.value[1]) {
          setValueRangeFormatted([
            format(newValue.value[0], 'E, dd MMM'),
            format(newValue.value[1], 'E, dd MMM'),
          ]);
          setFieldValue(name, {
            departure:
              newValue.value[0] && format(newValue.value[0], 'yyyy-MM-dd'),
            return:
              newValue.value[1] && format(newValue.value[1], 'yyyy-MM-dd'),
          });
        }
      }
    };
    const onChangeSingle = (newValue) => {
      if (newValue?.value && isValid(newValue.value)) {
        setDateValueFormatted(format(newValue.value, 'E, dd MMM'));
        setValue(newValue.value);
        setFieldValue(
          name,
          newValue.value && format(newValue.value, 'yyyy-MM-dd')
        );
      }
    };
    let isMobileRange = useCallback(() => {
      return mode === 'range' && width <= 1024;
    }, [mode, width]);

    function rangeHeader() {
      return (<div className='w-full'>
        <p className='text-xs text-green-400'>*Select your travel period. Click on month label to change the mode to month view</p>
        <div className='flex w-full'>
          <CalendarPrev />
          <CalendarNav className='flex w-full items-center flex-auto'/>
          <CalendarNext />
        </div>
      </div>);
    }

    return (
      <div className="w-full">
        {mode === 'range' ? rangeInputs() : renderSingle()}
        <ThemeProvider theme={pickerTheme}>
          {mode === 'range' && width > 1024 && (
            <div className={'relative'}>
              <Datepicker
                controls={['calendar']}
                theme="ios"
                themeVariant={'light'}
                select="range"
                calendarType="month"
                renderCalendarHeader={rangeHeader}
                firstDay={1}
                isOpen={open}
                min={minDate}
                rangeStartLabel={labelStart}
                rangeEndLabel={labelEnd}
                anchorAlign={'end'}
                max={maxDate}
                value={valueRange ? valueRange : []}
                startInput={start}
                endInput={end}
                onClose={() => setOpen(false)}
                onChange={(newDate) => onChange(newDate)}
                onOpen={onOpen}
                showInput={false}
                rangeHighlight={true}
                pages={2}
              />
            </div>
          )}
          {isMobileRange() && (
            <>
              <Datepicker
                controls={['calendar']}
                theme="ios"
                themeVariant={'light'}
                select="range"
                renderCalendarHeader={rangeHeader}
                isOpen={open}
                firstDay={1}
                min={minDate}
                max={maxDate}
                rangeStartLabel={labelStart}
                rangeEndLabel={labelEnd}
                value={valueRange ? valueRange : []}
                startInput={start}
                endInput={end}
                onClose={() => setOpen(false)}
                calendarScroll={'vertical'}
                touchUi={true}
                onChange={(newDate) => onChange(newDate)}
                onOpen={onOpen}
                showInput={false}
                buttons={['close']}
                onCancel={() => setOpen(false)}
                showOuterDays={true}
              />
            </>
          )}
          {mode === 'single' && width >= 1024 && (
            <div className={'relative'}>
              {single && (
                <Datepicker
                  controls={['calendar']}
                  theme="material"
                  themeVariant={'light'}
                  selectMultiple={false}
                  anchor={single}
                  firstDay={firstDayOfTheWeek}
                  isOpen={singleOpen}
                  max={valueRange[0] && !valueRange[1] ? maxDate : null}
                  min={allowPast ? null : minDate}
                  value={dateValue ? dateValue : minDate}
                  onClose={() => setSingleOpen(false)}
                  onChange={(newDate) => onChangeSingle(newDate)}
                  pages={1}
                  showInput={false}
                />
              )}
            </div>
          )}
          {mode === 'single' && width < 1024 && (
            <div className={'relative'}>
              {single && (
                <Datepicker
                  controls={['calendar']}
                  theme="material"
                  themeVariant={'light'}
                  selectMultiple={false}
                  touchUi={true}
                  calendarScroll={'vertical'}
                  isOpen={singleOpen}
                  firstDay={firstDayOfTheWeek}
                  showOuterDays={true}
                  max={valueRange[0] && !valueRange[1] ? maxDate : null}
                  min={allowPast ? null : minDate}
                  value={dateValue ? dateValue : minDate}
                  onClose={() => setSingleOpen(false)}
                  onChange={(newDate) => onChangeSingle(newDate)}
                  pages={1}
                  showInput={false}
                />
              )}
            </div>
          )}
        </ThemeProvider>
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Return true to skip rendering, false to re-render
    // Skip re-render if the condition is met
    if (
      prevProps.error !== nextProps.error ||
      prevProps.errorMessages !== nextProps.errorMessages ||
      prevProps.value !== nextProps.value ||
      prevProps.viewMode !== nextProps.viewMode ||
      prevProps.rangeValue?.[0] !== nextProps.rangeValue?.[0] ||
      prevProps.rangeValue?.[1] !== nextProps.rangeValue?.[1]
    ) {
      return false;
    }
    return true;
  }
);
